import React from 'react';

import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { StyleSheet, Text } from 'react-native';

import Chip from './components/chip';
import customTheme from './theme';
import Grid from './components/grid';

const theme = {
  ...DefaultTheme,
  ...customTheme,
};

export default function App() {
  const handleClick = () => {
    alert('You clicked the Chip.');
  };

  const handleDelete = () => {
    alert('You deleted the Chip.');
  };
  return (
    <PaperProvider theme={theme}>
      {/* <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '40px auto',
                maxWidth: 800,
            }}
        >
            <h4 style={{ textAlign: 'center' }}>
                @tractorzoom/chip
            </h4>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: 10,
                    justifyContent: 'center',
                }}
            >
                <Chip label='Basic' style={{ margin: 4 }} />
                <Chip label='Active' active style={{ margin: 4 }} />
                <Chip label='Disabled' disabled style={{ margin: 4 }} />
                <Chip label='Clickable' onClick={handleClick} style={{ margin: 4 }} />
                <Chip label='Deletable' onDelete={handleDelete} style={{ margin: 4 }} />
            </div>
        </div> */}
        <Text style={styles.text}>
          Tractor Zoom Demo Expo App
        </Text>
        <Grid></Grid>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  text: {
    alignContent: 'center',
    backgroundColor: '#FCDEFE',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginTop: 50,
  },
});
