import useStyles from './styles';
import { useTheme } from 'react-native-paper';
import { Card } from 'react-native-paper';
import { ScrollView, View } from 'react-native';
import React from 'react';

export default function Grid() {
    const classes = useStyles(useTheme());
    const getRandomNumber = () => Math.floor(Math.random() * 1000) + 1;

    return (
        <ScrollView style={classes.container}>
            <View style={classes.bodyContainer}>
                <View style={classes.grid}>
                    {new Array(8).fill('').map((k, i) => (
                        <Card key={i}  style={classes.item}>
                            <Card.Cover source={{uri: `https://picsum.photos/${getRandomNumber()}/200`}}/>
                        </Card>
                    ))}
                </View>
            </View>
        </ScrollView>
    )
}
