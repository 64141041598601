import { StyleSheet } from 'react-native';

const useStyles = (theme) => StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
      },
      grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      header: {
        height: 80,
        backgroundColor: 'blue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      item: {
        width: 200,
        margin: 8,
      },
      bodyContainer: {
        display: 'flex',
        marginTop: 50,
        flexDirection: 'column'
      },
      filterPane: {
        width: 100,
        marginRight: 50,
      },
      filterItem: {
        height: 50
      }
});

export default useStyles;
